import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from '../../constants';
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import './footer.css'; // Ensure your CSS is updated for the new design

const Footer = () => {
  const [message, setMessage] = useState('');
  const [messLoading, setMessLoading] = useState(false);

  const handleSend = () => {
    setMessLoading(true);
    const data_send = { message };
    
    axios.post(`${BASE_URL}messages/add_message`, data_send)
      .then((res) => {
        if (res.data.status === 'success') {
          setMessage('');
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch(() => {
        toast.error('حدث خطأ ما');
      })
      .finally(() => {
        setMessLoading(false);
      });
  };

  return (
    <footer className="footer-section">
      <div className="container">
        <div className="footer-cta pt-5 pb-5">
          <div className="row text-center">
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                <i className="fas fa-phone"></i>
                <div className="cta-text">
                  <h4>تواصل معنا</h4>
                  <span>9876543210</span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                <i className="far fa-envelope-open"></i>
                <div className="cta-text">
                  <h4>البريد الإلكترونى</h4>
                  <span>mail@info.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-lg-4 mb-50">
              <div className="footer-widget text-center">
                <div className="footer-logo">
                  <Link to="/">
                    <img src="/images/logo2 (2).png" className="img-fluid" alt="logo" />
                  </Link>
                </div>
                {/* <p>نتمنى لك تصفح رائع ومفيد</p>
                <div className="footer-social-icon">
                  <span>تابعنا</span>
                  <a href="#"><i className="fab fa-facebook-f facebook-bg"></i></a>
                  <a href="#"><i className="fab fa-twitter twitter-bg"></i></a>
                  <a href="#"><i className="fab fa-google-plus-g google-bg"></i></a>
                </div> */}
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div className="footer-widget">
                <h3>لينكات</h3>
                <ul>
                  <li><Link to="/">الرئيسيه</Link></li>
                  <li><Link to="/quran">القرءان</Link></li>
                  <li><Link to="/azkar">الأذكار</Link></li>
                  <li><Link to="/library">المكتبه</Link></li>
                  <li><Link to="/my_books">مكتبتى</Link></li>
                </ul>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
              <div className="footer-widget">
                <h3>أرسل لنا رأيك</h3>
                <textarea 
                  value={message} 
                  onChange={(e) => setMessage(e.target.value)} 
                  className="form-control" 
                  placeholder="إكتب رأيك" 
                  rows="4" 
                />
                <div className="text-center">
                  {messLoading ? (
                    <Spinner animation="border" />
                  ) : (
                    <button 
                      onClick={handleSend} 
                      className="btn btn-primary my-2" 
                      style={{ backgroundColor: "#9B102C", color: "white" }}
                    >
                      إرسال
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 text-center text-lg-left">
              <p>Copyright &copy; 2024, جميع الحقوق محفوظة لدي الذكر الحكيم</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
