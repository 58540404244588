import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BASE_URL } from '../../constants'
import axios from 'axios'
import { ShareSocial } from 'react-share-social'
import { toast } from 'react-toastify'
import { ThreeDots } from 'react-loader-spinner'
import './LitanyDetails.css' // Import the CSS file

const LitanyDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  let localData = localStorage.getItem('elzekr_data')
  let userData = localData && JSON.parse(localData)
  const [pageLoading, setPageLoading] = useState(false)
  const [shareLoading, setShareLoading] = useState(false)
  const [litany, setLitany] = useState({})
  const [loveLoading, setLoveLoading] = useState(false)

  const get_litany = () => {
    const data_send = {
      user_id: userData?.id
    }
    setPageLoading(true)
    axios.get(`${BASE_URL}litany/litany_details/${id}?user_id=${userData?.id || 0}`)
      .then((res) => {
        if (res.data.status === 'success') {
          setLitany(res.data.result)
        }
      })
      .catch(e => console.log(e))
      .finally(() => {
        setPageLoading(false)
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleShare = () => {
    if (userData == null) {
      toast.warn('سجل أولا')
      navigate('/login')
      return
    }
    setShareLoading(true)
    const data_send = {
      user_id: userData?.id
    }
    axios.post(`${BASE_URL}litany/share_it/${id}`, data_send)
      .then((res) => {
        if (res.data.status === 'success') {
          get_litany()
          toast.success(res.data.message)
        } else if (res.data.status === 'faild') {
          toast.error(res.data.message)
        } else {
          toast.error("حدث خطأ ما")
        }
      })
      .catch(e => console.log(e))
      .finally(() => {
        setShareLoading(false)
      })
  }

  const handleChangeLoveStatus = () => {
    if (userData == null) {
      toast.warn('سجل أولا')
      navigate('/login')
      return
    }
    setShareLoading(true)
    const data_send = {
      user_id: userData?.id
    }
    axios.post(`${BASE_URL}litany/love_it/${id}`, data_send)
      .then((res) => {
        if (res.data.status === 'success') {
          toast.success(res.data.message)
          get_litany()
        } else if (res.data.status === 'faild') {
          toast.error(res.data.message)
        } else {
          toast.error('حدث خطأ ما')
        }
      })
      .catch(e => console.log(e))
      .finally(() => {
        setShareLoading(false)
      })
  }

  useEffect(() => {
    get_litany()
  }, [])

  return (
    <div className='litany_details_page'>
      {pageLoading ? (
        <div className="loader">
          <ThreeDots />
        </div>
      ) : (
        <div className="litany_details">
          {litany.type === 'video' ? (
            <div className="video_container">
              <iframe
                src={litany.video_link}
                frameBorder="0"
                height={'220px'}
                width={'100%'}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              >
              </iframe>
              <div className="details">
                <h5>{litany?.title_ar}</h5>
                <p>{litany?.description_ar}</p>
              </div>
            </div>
          ) : (
            <div className="audio_container">
              <div className="details">
                <h5>{litany?.title_ar}</h5>
                <p>{litany?.description_ar}</p>
                <audio controls={true}>
                  <source src={litany.sound} type="audio/mpeg" />
                </audio>
              </div>
            </div>
          )}
          {shareLoading ? (
            <ThreeDots />
          ) : (
            <div className="target_social">
              <ShareSocial
                onSocialButtonClicked={handleShare}
                url={`https://islamic-website-ten.vercel.app/litany/${id}`}
                socialTypes={['facebook']}
              />
              <img onClick={handleChangeLoveStatus} style={{ width: '30px', cursor: 'pointer' }} src={litany?.fav ? "/images/heart.png" : "/images/not_heart.png"} alt="" />
              <img src={litany?.share ? "/images/share.png" : "/images/not_share.png"} style={{ width: '40px' }} alt="" />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default LitanyDetails
