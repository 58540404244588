import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { ShareSocial } from "react-share-social";
import { ThreeDots } from "react-loader-spinner";
import "./HadithDetails.css"; // Assuming CSS styles are stored here

const HadithDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  let localData = localStorage.getItem("elzekr_data");
  let userData = localData && JSON.parse(localData);
  const [hadith, setHadith] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);

  const getHadith = () => {
    const data_send = { user_id: userData?.id };
    setPageLoading(true);
    axios
      .post(`${BASE_URL}hadiths/get_hadith_details/${id}`, data_send)
      .then((res) => {
        if (res.data.status === "success") {
          setHadith(res.data.result);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setPageLoading(false);
      });
  };

  const handleShare = () => {
    if (!userData) {
      toast.warn("سجل أولا");
      navigate("/login");
      return;
    }
    setShareLoading(true);
    const data_send = { user_id: userData?.id };
    axios
      .post(`${BASE_URL}hadiths/share_it/${id}`, data_send)
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(res.data.message);
          getHadith();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setShareLoading(false);
      });
  };

  const handleChangeLoveStatus = () => {
    if (!userData) {
      toast.warn("سجل أولا");
      navigate("/login");
      return;
    }
    setShareLoading(true);
    const data_send = { user_id: userData?.id };
    axios
      .post(`${BASE_URL}hadiths/love_it/${id}`, data_send)
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(res.data.message);
          getHadith();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setShareLoading(false);
      });
  };

  useEffect(() => {
    getHadith();
  }, [id]);

  return (
    <div className="hadith_page">
      {pageLoading ? (
        <div className="loading">
          <ThreeDots />
        </div>
      ) : (
        <div className="hadith_content">
          <h5 className="hadith_title">{hadith.hadith}</h5>
          <div className="social_buttons">
            {/* <h2>أوامر</h2> */}
            <div className="share_buttons">
              <ShareSocial
                onSocialButtonClicked={handleShare}
                url={`https://islamic-website-ten.vercel.app/hadith_details/${id}`}
                socialTypes={["facebook"]}
              />
              <button onClick={handleChangeLoveStatus} className="like_button">
                {hadith?.fav ? (
                  <img src="/images/heart.png" alt="liked" />
                ) : (
                  <img src="/images/not_heart.png" alt="not liked" />
                )}
              </button>
              {/* {hadith?.share ? (
                <img src="/images/share.png" className="share_icon" alt="shared" />
              ) : (
                <img src="/images/not_share.png" className="share_icon" alt="not shared" />
              )} */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HadithDetails;
